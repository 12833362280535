const accessNestedObjectWithString = (object, key) => {
  return key.split('.').reduce((o, i) => o[i], object)
}

// create function for parse message with replace {{}} with value
function parseMessage(message, data) {
  const regex = /{{(.*?)}}/g
  const matches = message.match(regex)
  if (matches) {
    matches.forEach((match) => {
      const key = match.replace(/{{|}}/g, '')
      message = message.replace(match, accessNestedObjectWithString(data, key))
    })
  }
  return message
}

function parseErrorLocale(validationKey, params, customMessage = {}) {
  const LIST_ERRORS = {
    isGreaterThanBefore: '{{fieldName}} harus lebih besar dari sebelumnya',
    required: '{{fieldName}} tidak boleh kosong',
    minLength: '{{fieldName}} Setidaknya {{minLength.min}} karakter',
    maxLength: '{{fieldName}} Maksimal {{maxLength.max}} karakter',
    minValue: '{{fieldName}} tidak boleh kurang dari {{minValue.min}}',
    hasNumber: 'Setidaknya 1 angka',
    hasSpecialCharacter: 'Setidaknya 1 karakter khusus @$!%()*?&',
    hasUpperCase: 'Setidaknya 1 huruf besar',
    hasLowerCase: 'Setidaknya 1 huruf kecil',
    email: '{{fieldName}} harus menggunakan email yang benar',
    sameAs: '{{fieldName}} harus sama dengan {{sameAs.eq}}',
    sameAsWithCustomField: '{{fieldName}} harus sama dengan {{sameAsWithCustomField.customField}}',
    numeric: '{{fieldName}} mohon diisi dengan angka',
    decimal: '{{fieldName}} mohon diisi dengan angka',
    gteDate: '{{fieldName}} harus sama atau lebih dari tanggal {{gteDate.customField}}',
    lteDate: '{{fieldName}} harus sama atau kurang dari tanggal {{lteDate.customField}}',
    integer: '{{fieldName}} harus diisi dengan angka bulat',
    ...customMessage,
  }

  const errorMessage = LIST_ERRORS[validationKey]
  if (!errorMessage) return 'error'

  return parseMessage(errorMessage, params)
}

export function parseErrors(fieldName, vuelidateField, customMessage = {}) {
  const {
    // eslint-disable-next-line no-unused-vars
    $model,
    // eslint-disable-next-line no-unused-vars
    $invalid,
    // eslint-disable-next-line no-unused-vars
    $dirty,
    // eslint-disable-next-line no-unused-vars
    $anyDirty,
    // eslint-disable-next-line no-unused-vars
    $error,
    // eslint-disable-next-line no-unused-vars
    $anyError,
    // eslint-disable-next-line no-unused-vars
    $pending,
    $params,
    ...listError
  } = vuelidateField
  const result = {}
  for (const key in listError) {
    if (listError[key]) continue
    result[key] = parseErrorLocale(key, { ...$params, fieldName }, customMessage)
  }

  return result
}